import React from "react";
import VideoComponent from "../VideoComponent";

const SideCard = ({
  data,
  imageClasses,
  direction = "col",
  containerClasses,
  labelClass = "text-gray-1200",
  cardClass = "bg-white",
  descriptionClass = "text-gray-600",
  headingClass = "text-purple-1000 text-xl",
}) => {
  const {
    section_label,
    section_heading,
    section_description,
    section_video,
    section_image,
  } = data || {};
  const flexDirection = { col: "flex-col", reverse: "flex-col-reverse" };

  return (
    <section
      className={`justify-between rounded-3xl shadow-card ${cardClass} mb-6 h-full w-full text-left flex ${flexDirection[direction]} overflow-hidden ${containerClasses}`}
    >
      <div className="p-5 space-y-3 2xl:p-10 md:p-8 sm:space-y-5">
        {section_label && (
          <h3 className={`${labelClass} uppercase text-sm font-medium`}>
            {section_label?.text}
          </h3>
        )}
        {section_heading && (
          <h4 className={`lg:text-2.5xl text-2xl font-semibold ${headingClass}`}>
            {section_heading?.text}
          </h4>
        )}
        {section_description && (
          <p className={`${descriptionClass} text-base`}>
            {section_description?.text}
          </p>
        )}
      </div>

      {section_video?.url ? (
        <VideoComponent loop autoPlay muted className={`w-full ${imageClasses} ${direction=="reverse" ? "h-[442px]":"h-auto"}`}>
          <source
            src={section_video?.url}
            type="video/mp4"
            
          />
        </VideoComponent>
      ) : (
        <img
          src={section_image?.url}
          width={section_image?.dimensions?.width || 0}
          height={section_image?.dimensions?.height || 0}
          alt="ai-assistant-label"
          loading="lazy"
          className={`w-full ${imageClasses} ${direction=="reverse" ? "h-[442px]":"h-auto"}`}
        />
      )}
    </section>
  );
};

export default SideCard;
