import React from "react";
import NewHomeLeadFormWithAPIForm from "../NewHomeLeadFormWithAPI";

/**
 * This component renders better model in dicom-nifti
 * @param {string} {location} location of parent page
 * @param {string} {heading} data from prismic
 * @param {string} {description} data from prismic
 * @param {string} {ctaHeading} data from prismic
 * @param {string} {ctaDescription} data from prismic
 * @param {object} {modelTypes} data from prismic
 * @param {string} {pageName} name of parent page
 * @author Waqas Ahmad
 * @returns JSX.Element
 */
const BetterModels = ({
  location,
  heading,
  description,
  ctaHeading,
  ctaDescription,
  modelTypes,
  pageName,
}) => {
  return (
    <article className="relative pt-16 pb-16 overflow-hidden sm:pt-20 sm:pb-22 lg:pt-16 lg:pb-64">
      <section className="max-w-xs px-2 mx-auto sm:max-w-7xl sm:px-0">
        <section className="z-10 flex flex-col justify-between text-white lg:flex-row lg:gap-x-18 sm:gap-y-11 gap-y-7 lg:items-center">
          <section className="flex-1 lg:max-w-md xl:max-w-none">
            <h2 className="text-2xl sm:text-4.5xl tracking-tighter_3 sm:font-bold font-semibold sm:max-w-lg max-w-xs sm:px-0 px-6 sm:mx-0 mx-auto sm:text-left text-center">
              {heading}
            </h2>
            {description && (
              <p className="max-w-xs px-6 mt-5 text-base text-center lg:mt-6 tracking-tighter_3 sm:text-left lg:max-w-md sm:max-w-lg sm:px-0">
                {description}
              </p>
            )}
          </section>

          <section className="lg:max-w-auto sm:max-w-2xl max-w-xs sm:mx-0 mx-auto lg:space-y-7.5">
            {modelTypes?.map((item, index) => {
              const {
                section_feature_icon,
                section_feature_heading,
                section_feature_description,
              } = item || {};
              return (
                <section
                  className="flex flex-col items-center gap-5 lg:py-7.5 lg:pr-7.5 sm:py-6 sm:pr-6 sm:pl-0 pt-7.5 px-7.5 sm:flex-row better_model_card rounded-xl"
                  key={index}
                >
                  <img
                    src={section_feature_icon?.url}
                    width={0}
                    height={0}
                    alt={section_feature_icon?.alt || "Better Modal"}
                    loading="lazy"
                    className="flex items-center justify-center w-auto h-auto p-4 rounded-lg better_model_feature-text"
                  />
                  <section className="text-center sm:text-left">
                    <h6 className="text-xl font-semibold sm:text-2xl tracking-tighter_3">
                      {section_feature_heading?.text}
                    </h6>
                    <p className="lg:mt-3.5 sm:mt-3 mt-3.5 tracking-tighter_3 text-base sm:px-0 px-4">
                      {section_feature_description?.text}
                    </p>
                  </section>
                </section>
              );
            })}
          </section>
        </section>

        <section className="w-full max-w-5xl mx-auto mt-20 text-center text-white lg:mt-36 sm:mt-14">
          <h1 className="text-2.5xl lg:text-[50px] sm:text-3.25xl lg:leading-14 sm:leading-10 font-semibold w-full lg:max-w-2xl sm:max-w-lg mx-auto sm:px-0 px-1">
            {ctaHeading}
          </h1>

          <p className="max-w-2xl px-5 pt-5 mx-auto text-lg sm:text-xl lg:font-medium lg:max-w-3xl sm:px-12 md:px-6 lg:px-0 lg:pt-6 sm:pt-7 sm:pb-14 pb-11">
            {ctaDescription}
          </p>

          <section className="flex justify-center">
            <NewHomeLeadFormWithAPIForm
              pageName={pageName}
              location={location}
              btnText="Get started"
              isCustomBtnBg={true}
              btnColor="dicom-btn border-0 px-5 h-[50px]"
            />
          </section>
        </section>
      </section>
    </article>
  );
};

export default BetterModels;
