import React from "react";

const TrustedBrands = ({ trusted_brands_list, customClass, widthClass,textStyle = "sm:text-sm text-2xs" }) => {
  return (
    <section
      className={`${customClass} pt-12 mx-auto space-y-6 section_container lg:py-3`}
    >
      <div className="img_grayscale_opacity">
        <p
          className={`text-center uppercase ${textStyle} text-gray-1300 tracking-[0.07em]`}
        >
          {trusted_brands_list?.primary?.label?.text}
        </p>
      </div>

      <div
        className={`flex flex-wrap justify-center gap-2 mx-auto sm:flex-nowrap ${
          widthClass
            ? "sm:gap-4 lg:gap-1 xl:gap-4 max-w-3xl"
            : "sm:gap-4 lg:gap-2 xl:gap-6  xl:max-w-5xl sm:max-w-4xl "
        } `}
      >
        {trusted_brands_list?.items?.map((item, index) => {
          return (
            <div
              key={`brand_${index}`}
              className={`${
                widthClass ||
                (item?.brand_image?.dimensions?.width == 93 &&
                item?.brand_image?.dimensions?.height == 44
                  ? "xl:min-w-[170px] min-w-[100px] xl:min-h-[90px] xl:-mt-2"
                  : item?.brand_image?.dimensions?.width == 67 &&
                    item?.brand_image?.dimensions?.height == 14
                  ? "xl:min-w-[150px] min-w-[100px]"
                  : "xl:min-w-[110px]")
              } flex justify-center col-span-1 img_grayscale_opacity lg:max-w-[165px] max-w-[80px] lg:max-h-[60px] lg:min-w-[105px]`}
            >
              <img
                src={item?.brand_image?.url}
                alt={item?.brand_image?.alt || "Brand logo"}
                className={`w-full h-full img_grayscale ${
                  item?.brand_image?.dimensions?.height >= 20 &&
                  item?.brand_image?.dimensions?.height <= 40 &&
                  "lg:px-4"
                }`}
                loading="lazy"
                width={item?.brand_image?.dimensions?.width || 0}
                height={item?.brand_image?.dimensions?.height || 0}
              />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default TrustedBrands;
