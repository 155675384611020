import React from "react";
import VideoComponent from "../VideoComponent";

/**
 * This component renders a card in dicom-nifti
 * @param {Object} {data} data from prismic
 * @param {string} {customClass} classes coming from HOC
 * @author Waqas Ahmad
 * @returns JSX.Element
 */
const EnterpriseCard = ({ data, customClass }) => {
  const { card_heading, card_video, card_image, card_icon } = data || {};

  return (
    <section className={`grid gap-y-6 lg:w-auto sm:w-128 ${customClass}`}>
      {card_video?.url ? (
        <VideoComponent
          loop
          autoPlay
          muted
          className={`w-full h-auto rounded-lg justify-self-end sm:py-8 pt-9 pb-5 lg:px-7 sm:px-36 pl-3 `}
        >
          <source src={card_video?.url} type="video/mp4" />
        </VideoComponent>
      ) : (
        <img
          src={card_image?.url}
          width={card_image?.dimensions?.width || 0}
          height={card_image?.dimensions?.height || 0}
          alt={"card_heading"}
          loading="lazy"
          className="w-full h-auto lg:px-7 sm:px-36 pl-3 sm:py-8 pt-9 pb-5"
        />
      )}
      <div className="flex flex-col justify-center justify-items-center gap-y-3 mb-7 items-center">
        <img
          src={card_icon?.url}
          width={card_icon?.dimensions?.width || 0}
          height={card_icon?.dimensions?.height || 0}
          alt={`${card_heading}-icon`}
          loading="lazy"
          className={`sm:w-7.5 sm:h-7.5 w-6 h-6`}
        />
        <p className="text-gray-1500 font-medium tracking-tighter_3 text-center text-base sm:text-xl">
          {card_heading?.text}
        </p>
      </div>
    </section>
  );
};

export default EnterpriseCard;
